import { ConfigTypes, getConfig } from 'projects/sharedComponents/src/services/getConfig/getConfig';

import { AccountDetails } from 'clientServerShared/types/account';

export function getEmbarkCDNUrl(): string {
  return getConfig(ConfigTypes.EMBARK_CDN_BASE_URL);
}

export function getMyEvLegacyLink(): string {
  return getConfig(ConfigTypes.MY_EV_LEGACY_URL);
}

export function getMyEvContactUsLink(googleAnalyticsQueryString?: string): string {
  const baseContactUsUrl = 'https://help.embarkvet.com/hc/en-us/requests/new';

  if (!!googleAnalyticsQueryString) {
    return `${baseContactUsUrl}${googleAnalyticsQueryString}`;
  }

  return baseContactUsUrl;
}

export function buildSharePublicProfileLink(dogHandle: string): string {
  return `http://embk.me/${dogHandle}?utm_campaign=cns_ref_dog_pub_profile&utm_medium=other&utm_source=embark`;
}

export type UrlPathPrefix = `/${string}`;

export function buildEmbarkCDNUrl(urlPath: UrlPathPrefix): string {
  return buildUrlFromConfig(ConfigTypes.EMBARK_CDN_BASE_URL, urlPath);
}

export function buildMyEvLegacyLink(urlPath: UrlPathPrefix): string {
  return buildUrlFromConfig(ConfigTypes.MY_EV_LEGACY_URL, urlPath);
}

export function buildApiUrlLink(urlPath: UrlPathPrefix): string {
  return buildUrlFromConfig(ConfigTypes.API_URL_BASE, urlPath);
}

export function buildShopifyLink(accountDetails: AccountDetails, urlSuffix: string): string {
  if (urlSuffix.startsWith('/')) {
    urlSuffix = urlSuffix.substring(1, urlSuffix.length);
  }

  return `${accountDetails.shopLink}/${urlSuffix}`;
}

export function buildUrlFromConfig(configType: ConfigTypes, urlPath: UrlPathPrefix): string {
  let baseUrl = getConfig(configType);
  if (baseUrl.endsWith('/')) {
    baseUrl = baseUrl.substring(0, baseUrl.length - 1);
  }

  let url = urlPath as string;
  if (url.startsWith('/')) {
    url = url.substring(1, url.length);
  }

  return `${baseUrl}/${url}`;
}
