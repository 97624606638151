import './petResultsNav.scss';

import {
  extractPetTrackingFields,
  useGTMPetDataLayer,
  useTrackReactRouterPageView,
} from 'projects/sharedComponents/src/services/eventTracking/eventTracking';

import { AccountDetails } from 'clientServerShared/types/account';
import { Header } from 'projects/sharedComponents/src/header/header';
import { PetResultsNavFragment } from 'projects/sharedComponents/src/generated/graphql-client-types';
import { PetResultsNavItems } from 'projects/sharedComponents/src/pet/navigation/petResultsNavItems';
import { VFC } from 'react';
import { useHeapEventProperties } from 'projects/sharedComponents/src/hooks/useHeapEventProperties';

export type PetResultsNavProps = {
  accountDetails: AccountDetails;
  pet: PetResultsNavFragment;
  /**
   * Controls how routing works for pages in React versus pages in Handlebars.
   * If a page is in React, we use React Router to link to other React pages.
   * If a page is in Handlebars, we always use a full server round trip.
   */
  isReactPage: boolean;
};

export const PetResultsNav: VFC<PetResultsNavProps> = (props) => {
  const { accountDetails, pet, isReactPage } = props;
  useGTMPetDataLayer(pet);
  useHeapEventProperties(extractPetTrackingFields(pet));
  useTrackReactRouterPageView(extractPetTrackingFields(pet));

  return (
    <div className="petResultsNav">
      <Header accountDetails={accountDetails} logoLinksToLegacyMyEV />
      <div className="petResultsNav-scrollContainer">
        <nav className="petResultsNav-nav" aria-label="Pet Results">
          <PetResultsNavItems pet={pet} accountDetails={accountDetails} isReactRouterLinks={isReactPage} />
        </nav>
      </div>
    </div>
  );
};
